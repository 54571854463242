import GlobalStyle from "./styles/global";
import Main from "../src/pages/Main";
import "animate.css";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { projectTheme } from "./styles/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <ThemeProvider theme={projectTheme}>
      <GlobalStyle />
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
