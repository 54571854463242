import axios from "axios";

const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'     
    //   token: "eMJAxiwSJ1ukrk4i1LoYxZwTnAHKd05h2IFHPFJAOw1ZOmdMgkRDmvCmQMJrjMZzwJ663e580830eacd",
    //   entity: "transforma-brasil",
}

const api = axios.create({
    baseURL: 'https://landpages.transformabrasil.com.br/api/',
    headers,
    proxy: false
})
 
export default api