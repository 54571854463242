export const projectTheme = {
  colors: {
    blue: "#18235E",
    white: "#FFFFFF",
    green: '#21D170',
    grey:'#717F91',
    errorBorder:'#ff5100',
    feedbackErrorBg: "#fcb3a6",
    feedbackErrorFontColor: "#ff0000",
  }
};
