import styled, { css } from "styled-components";
import Calendar from "../assets/icon/icon-schedule.svg";
import Clock from "../assets/icon/icon-clock.svg";
import forWho from "../assets/icon/forWho.svg";
import { projectTheme } from "../styles/theme";
import { useEffect, useState } from "react";

type SlideProps = {
  slideTitle?: string;
  slideDescripton?: string;
  forWhatPublic?: string;
  initialIndex?: number;
  fixedDate?: string;
  hour?: string;
};

export const SlidesProjects = ({
  slideTitle,
  slideDescripton,
  forWhatPublic,
  initialIndex,
  fixedDate,
  hour,
}: SlideProps) => {
  const [todayIs, setTodayIs] = useState<Date>();

  // //
  // const datesEvent = [
  //   "2022/01/10",
  //   "2022/01/17",
  //   "2022/01/24",
  //   "2022/01/31",
  //   "2022/02/07",
  //   "2022/02/14",
  //   "2022/02/21",
  //   "2022/02/28",
  //   "2022/03/07",
  //   "2022/03/14",
  //   "2022/03/21",
  //   "2022/03/28",
  //   "2022/04/04",
  //   "2022/04/11",
  //   "2022/04/18",
  //   "2022/04/25",
  //   "2022/05/02",
  //   "2022/05/09",
  //   "2022/05/16",
  //   "2022/05/23",
  //   "2022/06/06",
  //   "2022/06/13",
  //   "2022/06/20",
  //   "2022/06/27",
  //   "2022/07/04",
  //   "2022/07/11",
  //   "2022/07/18",
  //   "2022/07/25",
  //   "2022/08/01",
  //   "2022/08/08",
  //   "2022/08/15",
  //   "2022/08/22",
  //   "2022/09/05",
  //   "2022/09/12",
  //   "2022/09/19",
  //   "2022/09/26",
  //   "2022/10/03",
  //   "2022/10/10",
  //   "2022/10/17",
  //   "2022/10/24",
  //   "2022/11/07",
  //   "2022/11/14",
  //   "2022/11/21",
  //   "2022/11/28",
  //   "2022/12/05",
  //   "2022/12/12",
  //   "2022/12/19",
  //   "2022/12/26",
  // ];
  const [currentEvent, setCurrentEvent] = useState<Date>(new Date());
  //REMOVE THIS AFTER 21/02
  //  useEffect(() => {
  //     let today: any = new Date(); //Get Today Date
  //     setTodayIs(today);
  //     let index = initialIndex - 1;
  //     let dateEvent = new Date(date[initialIndex - 1]);
  //     while (today > dateEvent) {
  //       index = index + 4;
  //       dateEvent = new Date(date[index]);
  //     }
  //     setCurrentEvent(dateEvent);
  //   }, [initialIndex]);

  // useEffect(() => {
  //   let today: any = new Date(); //Get Today Date
  //   setTodayIs(today);
  //   let index = initialIndex - 1;
  //   let dateEvent = new Date(datesEvent[initialIndex - 1]);
  //   while (today > dateEvent) {
  //     index = index + 4;
  //     dateEvent = new Date(datesEvent[index]);
  //   }
  //   setCurrentEvent(dateEvent);
  // }, [initialIndex]);

  return (
    <DivColumn className="animate__animated animate__fadeIn">
      <Title>{slideTitle}</Title>
      <DivFlex>
        <div className="schedule">
          <img src={Calendar} alt="Data" />
          <p>{fixedDate}</p>
          {/* <p>{fixedDate.toLocaleDateString("pt-BR", { dateStyle: "long" })}</p> */}
        </div>
        <div className="schedule">
          <img src={Clock} alt="Hora" />
          <p>{hour}</p>
        </div>
        <div className="schedule">
          <img src={forWho} alt="Hora" />
          <p>{forWhatPublic}</p>
        </div>
      </DivFlex>
      <Description>{slideDescripton}</Description>
    </DivColumn>
  );
};

const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 990px;
  text-align: center;
  margin: 0 auto;
`;

const DivFlex = styled.div(
  () => css`
    display: flex;
    align-items: center;
    gap: 32px;
    text-align: center;
    .schedule {
      display: flex;
      gap: 16px;
      p {
        font-size: 16px;
        line-height: 28px;
        color: ${projectTheme.colors.grey};
        font-family: "Inter", sans-serif;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    @media screen and (max-width: 510px) {
      flex-direction: column;
      gap: 8px;
    }
  `
);
const Title = styled.h1(
  () => css`
    font-size: 40px;
    font-family: "Poppins", sans-serif;

    color: ${projectTheme.colors.blue};
    margin-bottom: 42px;
    width: 100%;
    max-width: 540px;

    margin-top: 40px;
    @media screen and (max-width: 680px) {
      width: 95%;
    }
    @media screen and (max-width: 4900px) {
      font-size: 30px;
      width: 90%;
    }
  `
);

const Description = styled.p(
  () => css`
    margin-top: 24px;
    font-size: 16px;
    line-height: 28px;
    color: ${projectTheme.colors.grey};
    font-family: "Inter", sans-serif;
    font-weight: 400;
    width: 60%;
    @media screen and (max-width: 590px) {
      width: 90%;
    }
  `
);
