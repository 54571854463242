import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { SlidesProjects } from "../components/SlidesProjects";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { projectTheme } from "../styles/theme";
import * as yup from "yup";
import axios from "axios";
import Input from "../components/Inputs/Input";
import InputSelect from "../components/Inputs/InputSelect";
import TransformaLogo from "../assets/icon/logo.svg";
import BannerLG from "../assets/images/vitrine_lg.png";
import BannerMD from "../assets/images/vitrine_md.png";
import BannerSM from "../assets/images/vitrine-sm.png";
import FooterLG from "../assets/images/footer-lg.svg";
import FooterMD from "../assets/images/footer-md.svg";
import FooterSM from "../assets/images/footer-sm.svg";
import ArrowDown from "../assets/icon/arrow-down.svg";
import Success from "../assets/icon/sucess.svg";
import NeoEnergia from "../assets/icon/neo-energia.svg";
import Facebook from "../assets/icon/facebook.svg";
import Instagram from "../assets/icon/instagram.svg";
import Youtube from "../assets/icon/youtube.svg";
import Twitter from "../assets/icon/twitter.svg";
import Transforma from "../assets/images/transforma.svg";
import TgsSolidario from "../assets/images/tgs-solidario.svg";
import TransformaBh from "../assets/images/transformabh.svg";
import TransformaCuruaru from "../assets/images/transforma-curuaru.svg";
import TransformaPetrolina from "../assets/images/transforma-petrolina.svg";
import DOMPurify from "dompurify";
import { format } from "date-fns";

import * as https from "https";

import { phoneMask } from "../utils/mask";
import { uf } from "../utils/uf";
import api from "../services/api";
type IsActiveSlide = {
  isActive: boolean;
};

type FormValues = {
  name: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  event: number;
  term?: boolean | string;
};

export interface IEvent {
  id?: number;
  title?: string;
  call?: string;
  date?: string;
  hour?: string;
  public?: string;
  summary?: string;
  content?: string;
  status?: string;
}

export interface IPage {
  id?: number;
  title: string | Node;
  slug?: string;
  summary: string | Node;
  content: string | Node;
  status: string | Node;
}

const initialValues: FormValues = {
  name: "",
  email: "",
  phone: "",
  city: "",
  state: "",
  event: 0,
  term: false,
};

type StateProps = {
  uf: string;
};

const Main = () => {
  const [thisSlide, setThisSlide] = useState<number>(1);
  const [districts] = useState<StateProps[]>(uf);
  const [feedbackOK, setfeedbackOK] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [loadingMain, setLoadingMain] = useState<boolean>(true);

  const [listEvents, setListEvents] = useState<IEvent[]>([]);
  const [page, setPage] = useState<IPage[]>();
  const [pageOne, setPageOne] = useState<IPage[]>();

  const getPage = async (slug: string) => {
    await api
      .get<IPage[]>(`page/${slug}`)
      .then((response) => setPage(response.data));
  };

  const getPageOne = async (slug: string) => {
    await api
      .get<IPage[]>(`page/${slug}`)
      .then((response) => setPageOne(response.data));
  };

  useEffect(() => {
    getPage("venha-aprender-mais-sobre-lideranca-social-e-trabalho-voluntario");
    getPageOne("sobre-o-transforma-brasil");

    api.get<IEvent[]>("events").then((response) => {
      setListEvents(response.data);
      setLoadingMain(false);
    });

    if (feedbackOK) {
      setTimeout(() => {
        setfeedbackOK(false);
      }, 5000);
    }
  }, [feedbackOK]);

  if (!page) return null;
  if (!pageOne) return null;
  if (!listEvents) return null;

  const renderSlide = () => {
    switch (thisSlide) {
      case 1:
        let dateListEvt = new Date(String(listEvents[1].date));
        let data = dateListEvt.setDate(dateListEvt.getDate() + 1);
        return (
          <SlidesProjects
            slideTitle={listEvents[1].call}
            slideDescripton={listEvents[1].summary}
            forWhatPublic={listEvents[1].public}
            initialIndex={listEvents[1].id}
            // fixedDate={format(new Date(String(listEvents[1].date)), 'dd/MM/yyyy')}
            fixedDate={format(data, "dd/MM/yyyy")}
            hour={listEvents[1].hour}
          />
        );

      default:
        let dateListEvt0 = new Date(String(listEvents[0].date));
        let data0 = dateListEvt0.setDate(dateListEvt0.getDate() + 1);
        return (
          <SlidesProjects
            slideTitle={listEvents[0].call}
            slideDescripton={listEvents[0].summary}
            forWhatPublic={listEvents[0].public}
            initialIndex={listEvents[0].id}
            // fixedDate={format(new Date(String(listEvents[1].date)), 'dd/MM/yyyy')}
            fixedDate={format(data0, "dd/MM/yyyy")}
            hour={listEvents[0].hour}
          />
        );
    }
  };

  const handleChangeSlide = (direction: string) => {
    if (direction === "prev") {
      if (thisSlide === 1) {
        setThisSlide(2);
      } else {
        setThisSlide(thisSlide - 1);
      }
    } else {
      if (thisSlide === 2) {
        setThisSlide(1);
      } else {
        setThisSlide(thisSlide + 1);
      }
    }
  };

  const renderTitleSwitch = () => {
    switch (thisSlide) {
      case 1:
        return "Celebração On-line do dia do Voluntariado";
      case 2:
        return "Conexão do Bem: Boas vindas";
      case 3:
        return "Conexão do Bem: Boas vindas";
      default:
        return "Celebração On-line do dia do Voluntariado";
    }
  };

  const FormSchema = yup.object().shape({
    name: yup.string().required("Campo Obrigatório!"),
    email: yup.string().required("Campo Obrigatório!"),
    phone: yup.string().required("Campo Obrigatório!"),
    city: yup.string().required("Campo Obrigatório!"),
    state: yup.string().required("Campo Obrigatório!"),
    term: yup.string().required(),
  });

  const handleSubmit = async (values: FormValues) => {
    let phoneFormated = "";
    if (values.phone.length > 11) {
      phoneFormated = values.phone
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "")
        .replace(" ", "")
        .replace(" ", "");
    }
    const data: FormValues = {
      name: values.name,
      email: values.email,
      phone: phoneFormated ? phoneFormated : values.phone,
      city: values.city,
      state: values.state,
      event: values.event,
      term: values.term,
    };
    setLoading(true);
    let eventAll = data.event.toString() == "" ? [] : Array.of(data.event);
    //Linha acima será vazia caso o usuário não selecionar nenhum evento.
    //Caso Selecione irá gerar um array a partir do(s) evento(s)
    let arrayLenght = Array.isArray(data.event) ? data.event.length : 0;
    
    if (Array.isArray(data.event) && data.event.toString() !== "") {
      let dataSend = {
        name: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        event: "",
        term: values.term
      }
      for (let indice = 0; indice < arrayLenght; indice++) {
        dataSend = {
          name: values.name,
          email: values.email,
          phone: phoneFormated ? phoneFormated : values.phone,
          city: values.city,
          state: values.state,
          event: (data.event[indice]),
          term: values.term
        }
        
        await api.post("/event", dataSend).then((res: any) => {
          if (res.status === 201) {
            setfeedbackOK(true);
          } else {
            toast.warning("Algo deu errado 😥");
          }
        }).catch((err) => {
          toast.warning("Algo deu errado 😥");
        });
      }
    } else {
      toast.info('Selecione pelo menos um evento', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (feedbackOK === true) {
      setLoading(false);
    }
    setLoading(false);
    values.email = "";
    values.name = "";
    values.phone = "";
    values.city = "";
    values.state = "";
    values.event = 0;
    values.term = "";
  };

  return (
    <>
      {loadingMain === false && (
        <Container>
          <Header>
            <ul>
              <li>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://transformabrasil.com.br/")
                  }
                  src={TransformaLogo}
                  alt="Transforma brasil"
                />
              </li>
              <li>
                <Subscription href="#subscription">Inscrições</Subscription>
              </li>
            </ul>
          </Header>
          <Banner>
            <BannerContent>
              <div className="inner-content">
                <h1 className="bold">{page[0].title}</h1>

                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(page[0].content),
                  }}
                ></p>

                <p className="meet_out_events">Conheça nossos eventos</p>
                <a href="#events">
                  {" "}
                  <img id="arrow-down" src={ArrowDown} alt="" />
                </a>
              </div>
            </BannerContent>
          </Banner>
          <Projects id="events">
            <NavProjects>
              <ul>
                <li>
                  <ButtonOption
                    isActive={thisSlide === 3}
                    onClick={() => setThisSlide(3)}
                  >
                    <p>{listEvents[0].title}</p>
                  </ButtonOption>
                </li>
                <li>
                  <ButtonOption
                    isActive={thisSlide === 1}
                    onClick={() => setThisSlide(1)}
                  >
                    <p>{listEvents[1].title}</p>
                  </ButtonOption>
                </li>
              </ul>
            </NavProjects>

            <NavProjectMobile>
              <DivFlexNavProjectMobile>
                <Backwards
                  onClick={() => handleChangeSlide("prev")}
                  className="fas fa-chevron-left"
                />
                <TitleNavProjectMobile>
                  <h3>{renderTitleSwitch()}</h3>
                </TitleNavProjectMobile>
                <Forwards
                  onClick={() => handleChangeSlide("next")}
                  className="fas fa-chevron-right"
                />
              </DivFlexNavProjectMobile>
            </NavProjectMobile>
            <SlideContainer>{renderSlide()}</SlideContainer>
          </Projects>
          <FormSection id="subscription">
            {feedbackOK === true ? (
              <SucessFeedback className="animate__animated animate__fadeIn">
                <DivFlex>
                  <Content>
                    <img src={Success} alt="" />
                    <h1>Inscrição submetida com sucesso</h1>
                    <p>
                      Em breve você receberá um retorno sobre <br /> sua
                      inscrição. Fique atento ao seu e-mail.
                    </p>
                  </Content>
                </DivFlex>
              </SucessFeedback>
            ) : (
              <FormContainer className="animate__animated animate__fadeIn">
                <h3>Garanta já sua participação gratuita!</h3>
                <FormContent>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={FormSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      isValid,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Input
                          placeholder="Seu nome"
                          value={values.name}
                          onChange={handleChange("name")}
                          onBlur={handleBlur("name")}
                          error={
                            touched.name && errors.name
                              ? errors.name
                              : undefined
                          }
                          type="text"
                          name="name"
                          autoComplete="name"
                          autoCapitalize="words"
                        />
                        <Input
                          placeholder="Seu e-mail"
                          value={values.email}
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                          error={touched.email ? errors.email : undefined}
                          type="email"
                          name="email"
                          autoComplete="off"
                          autoCapitalize="words"
                        />
                        <Input
                          placeholder="Seu telefone"
                          value={phoneMask(values.phone)}
                          onChange={handleChange("phone")}
                          onBlur={handleBlur("phone")}
                          error={touched.phone ? errors.phone : undefined}
                          type="tel"
                          name="phone"
                          maxLength={15}
                        />
                        <DivFlex>
                          <Input
                            className="city"
                            placeholder="Sua Cidade"
                            value={values.city}
                            onChange={handleChange("city")}
                            onBlur={handleBlur("city")}
                            error={touched.city ? errors.city : undefined}
                            type="text"
                            name="city"
                            autoComplete="on"
                          />
                          <InputSelectWrapper>
                            <InputSelect
                              placeholder="UF"
                              value={values.state}
                              onChange={handleChange("state")}
                              onBlur={handleBlur("state")}
                              error={touched.state ? errors.state : undefined}
                              name="state"
                              autoComplete="off"
                              autoCapitalize="off"
                            >
                              <option>UF</option>

                              {districts.map(
                                (item: StateProps, idx: number) => {
                                  return (
                                    <option value={item.uf} key={idx}>
                                      {item.uf}
                                    </option>
                                  );
                                }
                              )}
                            </InputSelect>
                          </InputSelectWrapper>
                        </DivFlex>
                        <CheckboxContainer>
                          <h4>Evento(s) que deseja participar</h4>
                          <CheckboxContent>
                            <FlexContainer>
                              <DivFlexLabel>
                                <InputCheckbox
                                  wrapperStyle={{ flex: "none" }}
                                  onChange={handleChange("event")}
                                  onBlur={handleBlur("event")}
                                  type="checkbox"
                                  name={String(listEvents[1].id)}
                                  id={String(listEvents[1].id)}
                                  value={listEvents[1].id}
                                />
                                <Label htmlFor="event1">
                                  {listEvents[1].title}
                                </Label>
                              </DivFlexLabel>
                              <DivFlexLabel>
                                <InputCheckbox
                                  wrapperStyle={{ flex: "none" }}
                                  onChange={handleChange("event")}
                                  onBlur={handleBlur("event")}
                                  type="checkbox"
                                  name={String(listEvents[0].id)}
                                  id={String(listEvents[0].id)}
                                  value={listEvents[0].id}
                                />
                                <Label htmlFor="event2">
                                  {listEvents[0].title}
                                </Label>
                              </DivFlexLabel>
                            </FlexContainer>
                            <p>{errors.event ? errors.event : undefined}</p>
                          </CheckboxContent>
                          <CheckboxContainerTerms>
                            <DivFlexLabelTerms>
                              <InputCheckbox
                                wrapperStyle={{ flex: "none" }}
                                onChange={handleChange("term")}
                                onBlur={handleBlur("term")}
                                type="checkbox"
                                name="term"
                                id="term"
                                value="true"
                                disabled={checked}
                                required
                              />
                              <Label htmlFor="term">
                                Desejo receber emails e informações sobre a
                                plataforma.
                              </Label>
                            </DivFlexLabelTerms>
                          </CheckboxContainerTerms>
                          <Button
                            disabled={
                              values.email === "" ||
                              values.event === 0 ||
                              loading === true ||
                              values.term === false
                            }
                            type="submit"
                          >
                            Continuar
                          </Button>
                        </CheckboxContainer>
                      </Form>
                    )}
                  </Formik>
                </FormContent>
              </FormContainer>
            )}
          </FormSection>

          <AboutTbr>
            <div className="about-container">
              <h3>{pageOne[0].title}</h3>
              <p>{pageOne[0].summary}</p>
              <br />
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(pageOne[0].content),
                }}
              ></p>
              <br />
              <DivFlexAboutLogos>
                <a href="https://transformabrasil.com.br/" target="_blank">
                  <Img src={TransformaLogo} alt="Transforma Brasil" />
                </a>
                <a
                  href="https://www.neoenergia.com/pt-br/Paginas/default.aspx"
                  target="_blank"
                >
                  <Img src={NeoEnergia} alt="NeoEnergia" />
                </a>
              </DivFlexAboutLogos>
              <P>Parceiros</P>
            </div>
            <Partners>
              <a href="https://transformario.com/" target="_blank">
                <ImgPartners src={Transforma} alt="transforma" />
              </a>
              <a href="https://transformacaruaru.com.br/" target="_blank">
                <ImgPartners src={TransformaCuruaru} alt="transforma curuaru" />
              </a>
              <a href="https://tgssolidario.com.br/" target="_blank">
                <ImgPartners src={TgsSolidario} alt="tgs solidario" />
              </a>
              <a href="https://transformapetrolina.com.br/" target="_blank">
                <ImgPartners
                  src={TransformaPetrolina}
                  alt="transforma petrolina"
                />
              </a>
              <a href="https://transformabh.com.br/" target="_blank">
                <ImgPartners src={TransformaBh} alt="transforma bh" />
              </a>
            </Partners>
          </AboutTbr>

          <FooterBanner>
            <img className="footer-lg" src={FooterLG} alt="" />
            <img className="footer-md" src={FooterMD} alt="" />
            <img className="footer-sm" src={FooterSM} alt="" />
          </FooterBanner>

          <ContactUs>
            <a href="https://www.facebook.com/transforma.br/" target="_blank">
              <img src={Facebook} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/transforma.brasil"
              target="_blank"
            >
              <img src={Instagram} alt="Instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCS874ViYbmEx06W7n3C38fQ"
              target="_blank"
            >
              <img src={Youtube} alt="Youtube" />
            </a>
            <a href="https://twitter.com/transformabra" target="_blank">
              <img src={Twitter} alt="Twitter" />
            </a>
          </ContactUs>
        </Container>
      )}
      <ToastContainer />
    </>
  );
};

export default Main;

// Header
const Container = styled.main`
  max-width: 2560px;
  margin: 0 auto;
`;

const Header = styled.header`
  width: 80%;
  padding: 20px;
  margin: 0 auto;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
    margin: 0 auto;

    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const Subscription = styled.a(
  () => css`
    padding: 13px 32px;
    font-weight: 600;
    color: white;
    background-color: ${projectTheme.colors.blue};
    font-size: 14px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
    :hover {
      pointer-events: all;
      cursor: pointer;
    }
  `
);

// Banner
const Banner = styled.div`
  width: 100%;
  height: 700px;
  background-image: url(${BannerLG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 52px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 1440px) {
    height: 900px;
  }

  @media screen and (max-width: 1190px) {
    background-image: url(${BannerMD});
    height: 750px;
  }

  @media screen and (max-width: 600px) {
    background-image: url(${BannerSM});
  }
`;

const BannerContent = styled.div(
  () => css`
    .inner-content {
      h1 {
        font-size: 46px;
        line-height: 64px;
        font-weight: 700;
        width: 80%;

        font-family: "Poppins", sans-serif;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        width: 80%;
        font-family: "Inter", sans-serif;
      }
      text-align: center;
      margin: 0 25px 0 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      color: ${projectTheme.colors.white};

      .meet_out_events {
        font-size: 24px;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
      }
    }
    @media screen and (max-width: 600px) {
      text-align: center;
      .inner-content {
        h1 {
          font-size: 24px;
          line-height: 28px;
          width: 100%;
        }
        p {
          line-height: 20px;

          width: 100%;
        }
      }
    }
    a {
      padding-bottom: 20px;
    }
    #arrow-down {
      animation: slide 1s ease-in-out infinite;
      width: 20px;
    }
    @keyframes slide {
      0%,
      100% {
        transform: translate(0, 0);
      }

      50% {
        transform: translate(0, 15px);
      }
    }
  `
);

// Projects

const Projects = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 40px;
  margin-bottom: 40px;
`;

const NavProjects = styled.div`
  max-width: 70%;
  margin: 0 auto;

  ul {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const NavProjectMobile = styled.div`
  display: none;
  @media screen and (max-width: 615px) {
    display: block;
  }

  @media screen and (max-width: 500px) {
    margin-top: -20px;
  }
  @media screen and (max-width: 400px) {
    margin-top: -70px;
  }
`;

const DivFlexNavProjectMobile = styled.div`
  gap: 32px;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

const TitleNavProjectMobile = styled.div`
  h3 {
    color: ${projectTheme.colors.blue};
    font-size: 16px;
    font-family: "Inter";
    font-weight: 700;
    padding: 0 50px 16px 50px;
    border-bottom: 4px solid ${projectTheme.colors.green};
  }
`;
const Forwards = styled.i`
  font-size: 16px;
  color: ${projectTheme.colors.green};
  cursor: pointer;
  padding: 3px;
`;

const Backwards = styled.i`
  font-size: 16px;
  color: ${projectTheme.colors.green};
  cursor: pointer;
  padding: 3px;
`;

const ButtonOption = styled.button<IsActiveSlide>(
  ({ isActive }) => css`
    font-size: 16px;
    font-family: "Inter", sans-serif;
    color: ${isActive === true
      ? projectTheme.colors.blue
      : projectTheme.colors.grey};
    font-weight: ${isActive === true ? 600 : ""};
    padding-bottom: 2px;
    background: none;
    border: none;
    padding: 0 25px 20px 25px;
    border-bottom: 3px solid
      ${isActive === true ? projectTheme.colors.green : "#E6EAF6"}; //Specific color
    transition: all ease 0.3s;
    cursor: pointer;

    @media screen and (max-width: 590px) {
      text-align: center;
    }
  `
);

const SlideContainer = styled.div``;

// Form

const FormSection = styled.section`
  width: 100%;
  background: ${projectTheme.colors.green};
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  margin: 0 auto;
  max-width: 520px;

  h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: ${projectTheme.colors.blue};
    margin-bottom: 40px;
    text-align: center;
  }
`;
const FormContent = styled.div`
  width: 100%;
  @media screen and (max-width: 590px) {
    width: 90%;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 20px;
  border: none;
  border-radius: 12px;
  background-color: ${projectTheme.colors.blue};
  color: ${projectTheme.colors.white};
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  margin-top: 40px;
  cursor: pointer;

  :disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

const DivFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    gap: 0;
    > div {
      width: 100%;
    }
  }
`;

const InputSelectWrapper = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;

  @media screen and (max-width: 590px) {
    flex-direction: column;
    width: 90%;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: ${projectTheme.colors.blue};
    margin-top: 24px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 590px) {
    flex-direction: column;
  }
`;
const CheckboxContent = styled.div`
  display: flex;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
  > div {
    display: flex;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 590px) {
      width: 100%;
    }
  }
`;

const InputCheckbox = styled(Input)`
  box-sizing: border-box;
  box-shadow: 2px 2px 0px ${projectTheme.colors.green};
  background: ${projectTheme.colors.green};
  margin: 12px;
  margin-top: 0px;
  margin-right: 25px;
  &[type="checkbox"]::before {
    content: " ";
    border-radius: 4px;
    width: 24px;
    height: 24px;
    position: absolute;
    background: ${projectTheme.colors.green};
    border: 2px solid ${projectTheme.colors.white};
  }
  &[type="checkbox"]:checked::before {
    background: ${projectTheme.colors.blue};
    border: 2px solid ${projectTheme.colors.blue};
  }
  &[type="checkbox"]:checked::after {
    content: " ";
    width: 11px;
    height: 6px;
    position: absolute;
    margin-top: 7.8px;
    margin-left: 7px;
    border-radius: 1px;
    transform: rotate(-52deg);
    /* border left */
    border-left-style: solid;
    border-left-width: 3px;
    border-left-color: ${projectTheme.colors.white};

    /* border bottom */
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: ${projectTheme.colors.white};
  }
`;

const CheckboxContainerTerms = styled.div`
  padding-top: 2px;
`;
const DivFlexLabelTerms = styled.div`
  display: flex;
  align-items: flex-end;
  height: 25px;
  @media screen and (max-width: 490px) {
    height: 30px;
    align-items: flex-start;
  }
`;
const Label = styled.label`
  font-size: 16px;
  font-family: "Inter";
  font-weight: normal;
  color: ${projectTheme.colors.white};
`;
const DivFlexLabel = styled.div`
  display: flex;
  align-items: flex-end;
  width: 270px;
  padding-bottom: 2px;
  margin-bottom: 20px;

  @media screen and (max-width: 590px) {
    width: 100%;
  }
`;

const SucessFeedback = styled.div`
  max-width: 1440px;
  width: 100%;
  background-color: ${projectTheme.colors.green};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 300px auto;
  h1 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: ${projectTheme.colors.white};
    @media screen and(max-width: 590px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  p {
    margin-top: 16px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    font-family: "Inter";
    color: #274264;
    @media screen and(max-width: 590px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  @media screen and(max-width:590px) {
    width: 80%;
    margin: 300px auto;
  }
`;

const AboutTbr = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  text-align: center;
  /* justify-content: center; */

  h3 {
    font-family: "Poppins";
    font-size: 20px;
    color: ${projectTheme.colors.blue};
    margin-bottom: 40px;
  }

  p {
    font-family: "Inter";
    line-height: 28px;
    font-size: 20px;
    color: ${projectTheme.colors.grey};
  }
  .about-container {
    max-width: 720px;
    width: 100%;
  }
`;

const DivFlexAboutLogos = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 46px;
`;

const FooterBanner = styled.div`
  .footer-lg {
    width: 100%;
  }
  .footer-md {
    display: none;
  }
  .footer-sm {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    .footer-lg {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
    .footer-md {
      display: none;
    }
    .footer-sm {
      display: none;
    }
  }

  @media screen and (max-width: 1320px) {
    .footer-lg {
      display: none;
    }
    .footer-sm {
      display: none;
    }
    .footer-md {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 660px) {
    .footer-md {
      display: none;
    }
    .footer-lg {
      display: none;
    }
    .footer-sm {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }
`;

const ContactUs = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Img = styled.img`
  object-fit: contain;
  max-width: 200px;
  width: 100%;
  cursor: pointer;
`;

const P = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${projectTheme.colors.blue} !important;
  padding-top: 64px;
`;

const Partners = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1400px;
  width: 100%;
  flex-wrap: wrap;
  @media only screen and (max-width: 970px) {
    justify-content: space-evenly;
  }
`;

const ImgPartners = styled.img`
  object-fit: contain;
  max-width: 200px;
  width: 100%;
  padding-right: 25px;
  cursor: pointer;
  @media only screen and (max-width: 970px) {
    margin-top: 20px;
  }
`;

const FlexContainer = styled.div`
  @media only screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
`;
