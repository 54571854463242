import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

*{
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    scroll-behavior: smooth;
}

`;
